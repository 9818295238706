// 圖片放大加輪播
<template>
  <div class="image-carouselar-container" v-if="show">
    <div class="text-end">
      <i class="bi bi-x-lg action" @click="close"></i>
    </div>
    <div class="image-carouselar">
      <template v-for="(data, index) in images" :key="data.id">
        <i
          class="bi bi-chevron-left action"
          v-if="data.id === currentImgId"
          @click="move('left')"
        ></i>
        <Image
          :id="`single${data.imageHash}`"
          v-if="data.id === currentImgId"
          :lazy="false"
          :imageHash="data.imageHash"
          :alt="data.imageHash"
        ></Image>
        <i
          class="bi bi-chevron-right action"
          v-if="data.id === currentImgId"
          @click="move('right')"
        ></i>
        <button
          class="tw-btn tw-btn-success downloadBtn"
          v-if="data.id === currentImgId && !checkIsMobileDevice"
          @click="downloadPicture(data)"
        >
          下載此圖
        </button>
      </template>
    </div>
  </div>
</template>

<script>
// shot
import html2canvas from "html2canvas";

export default {
  /**
   * 可優化:
   * 1. 行動裝置長按滑動
   * 2. 圖片下方可加上所有圖片預覽
   * 3. 承上，點擊某張預覽圖舊就示該張圖片
   */
  props: {
    // 可從外部套用 css，目前尚為決定要套用在哪裡
    Iclass: {
      type: String,
      default: ''
    },
    // 圖片陣列
    images: {
      type: Array,
      default: []
    },
    // 從外部點擊圖片時，要傳遞圖片 id 進來，這樣才能知道預設要開哪張圖片
    currentImage: {
      type: Object,
      default: {
        id: 0,
      }
    }
  },
  data() {
    return {
      show: false,
      currentImgId: 0,
      // 判斷是否為行動裝置
      checkIsMobileDevice: false,
    }
  },
  watch: {
    currentImage: {
      handler(val) {
        console.log(val)
        if (val.id > 0) {
          this.show = true
          this.currentImgId = val.id
        }
      },
      deep: true
    }
  },
  created() {
    this.checkIsMobileDevice = this.$methods.checkIsMobileDevice()
  },
  methods: {
    // 關閉
    close() {
      this.currentImage.id = 0
      this.show = false
    },
    // 左右移動
    move(direction) {
      let currentIndex = 0
      this.images.some((item, index) => {
        if (this.currentImgId === item.id) {
          if (direction === 'left') currentIndex = index - 1
          else currentIndex = index + 1
        }
      })
      if (currentIndex > this.images.length - 1) this.currentImgId = this.images[0].id
      else if (currentIndex < 0) this.currentImgId = this.currentImgId = this.images[this.images.length - 1].id
      else this.currentImgId = this.currentImgId = this.images[currentIndex].id
    },
    // 下載圖片
    downloadPicture(imgInfo) {
      try {
        this.$methods.switchLoading('show')
        setTimeout(() => {
          const imgTag = document.getElementById(`single${imgInfo.imageHash}`)
          console.log(imgTag)
          html2canvas(imgTag, {
            backgroundColor: "#fff",
            useCORS: true,
            scale: 1,
          }).then((canvas) => {
            const downloadUrl = canvas.toDataURL("image/png")
            // 開始儲存圖片
            const now = this.$methods.moment(new Date()).format('YYYYMMDD-HHmmss')
            let alink = document.createElement("a")
            alink.href = downloadUrl
            alink.download = now
            alink.click()
            this.$methods.switchLoading('hide')
          })
        }, 500)
      } catch (err) {
        console.log(err)
        alert('您目前所使用的的瀏覽器無法下載')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.image-carouselar-container {
  position: fixed;
  z-index: 1101;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  .image-carouselar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90%;
    & img {
      max-width: 90vw;
      max-height: 90vh;
      @media screen and (max-width: 768px) {
        max-width: 70vw;
        max-height: 70vh;
      }
    }
  }
  .action {
    cursor: pointer;
    color: white;
    font-size: 50px;
    font-weight: border;
    min-width: 50px;
    &:hover {
      color: cadetblue;
    }
  }
  .downloadBtn {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
</style>